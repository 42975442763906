import { useState } from 'react'
import ImageEditor from 'filerobot-image-editor'


export default function FilerobotImageEditor() {
    const src = 'https://images.freecreatives.com/wp-content/uploads/2016/03/Gren-Nature-background-1.jpg'
    const [show, toggle] = useState(false)


    return (
        <>
            <div className='description'>
                <a href='https://github.com/scaleflex/filerobot-image-editor' target='__blank'>
                    Documantation
                </a>
            </div>

            <div
                style={{
                    width: '750px',
                    height: '729px',
                    backgroundImage: `url("https://images.freecreatives.com/wp-content/uploads/2016/03/Gren-Nature-background-1.jpg")`
                }}
                onClick={() => { toggle(true) }}>
            </div>

            <ImageEditor
                show={show}
                src={src}
                onClose={() => { toggle(false) }}
            />
        </>
    )
}