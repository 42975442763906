import { Switch, Route, Redirect, BrowserRouter, Link } from 'react-router-dom'
import ToastUIEditor from './Editors/ToastUIEditor'
import FilerobotImageEditor from './Editors/FilerobotImageEditor'
import PhotoEditorSDK from './Editors/PhotoEditorSDK'
import DesignBold from './Editors/DesignBold'
import './App.css'


export default function App() {
  return (
    <div className='wrapper'>
      <BrowserRouter>

        <nav className='header'>
          <h1>Image editors</h1>

          <Link to={{ pathname: '/toast-UI-editor' }}>
            TOAST UI Image Editor
          </Link>

          <Link to={{ pathname: '/filerobot-image-editor' }}>
            Filerobot Image Editor
          </Link>

          <Link to={{ pathname: '/photoeditorsdk' }}>
            Photo Editor SDK
          </Link>

          <Link to={{ pathname: '/designbold' }}>
            DesignBold
          </Link>

        </nav>

        <div className='editor'>
          <Switch>
            <Route path='/toast-UI-editor'>
              <ToastUIEditor />
            </Route>

            <Route path='/filerobot-image-editor'>
              <FilerobotImageEditor />
            </Route>

            <Route path='/photoeditorsdk'>
              <PhotoEditorSDK />
            </Route>

            <Route path='/designbold'>
              <DesignBold />
            </Route>

            <Redirect to='/toast-UI-editor' />
          </Switch>
        </div>
      </BrowserRouter>
    </div>
  )
}