import 'tui-image-editor/dist/tui-image-editor.css'
import ImageEditor from '@toast-ui/react-image-editor'
import Image from '../assets/images/Gren-Nature-background-1.jpg'


const myTheme = {
    // Theme object to extends default dark theme.
    'menu.normalIcon.color': '#8a8a8a',
    'menu.activeIcon.color': '#555555',
    'menu.disabledIcon.color': '#434343',
    'menu.hoverIcon.color': '#e9e9e9',
    'submenu.normalIcon.color': '#8a8a8a',
    'submenu.activeIcon.color': '#e9e9e9',
}


export default function ToastUIEditor() {
    return (
        <>
            <div className='description'>
                <a
                    href='https://github.com/nhn/tui.image-editor'
                    target='__blank'>
                    Documantation
                </a>
            </div>

            <ImageEditor
                includeUI={{
                    loadImage: { path: Image, name: 'SampleImage', },
                    theme: myTheme,
                    menu: ['resize', 'crop', 'flip', 'rotate', 'draw', 'text', 'icon', 'shape', 'filter', 'mask'],
                    initMenu: 'filter',
                    uiSize: { width: '1100px', height: '700px', },
                    menuBarPosition: 'bottom',
                }}
                cssMaxHeight={500}
                cssMaxWidth={700}
                selectionStyle={{ cornerSize: 20, rotatingPointOffset: 70, }}
                usageStatistics={true}
            />
        </>
    )
}