export default function DesignBold() {

    (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src =
            "https://sdk.designbold.com/button.js#app_id=e06d45a767";
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'db-js-sdk'))


    window.DBSDK_Cfg = {
        export_mode: ['download'],
        export_callback: function (resultUrl, documentId, exportTarget) {
            debugger
        },
    }

    return (
        <>
            <div className='description'>
                <a href='https://developers.designbold.com/document/designit' target='__blank'>
                    Documantation
                </a>
            </div>
            <div class="db-btn-design-me" data-db-width="400" data-db-height="600" data-db-unit="px" data-db-title="Design a 400 x 600"> </div>
            <div class="db-btn-design-me" data-db-doctype="facebook-cover" data-db-title="Design a Facebook Cover"> </div>
        </>
    )
}