import { useEffect } from 'react'
import { UIEvent, PhotoEditorSDKUI as ImageEditor } from 'photoeditorsdk'
import Image from '../assets/images/Gren-Nature-background-1.jpg'

export default function PhotoEditorSDK() {

    async function initEditor() {
        const editor = await ImageEditor.init({
            container: "#editor",
            image: Image, // Image url or Image path relative to assets folder
            license: "",
        });
        console.log("PhotoEditorSDK for Web is ready!");
        editor.on(UIEvent.EXPORT, (imageSrc) => {
            console.log("Exported ", imageSrc);
        })
    }

    useEffect(() => {
        initEditor()
    }, [])

    return (
        <>
            <div className='description'>
                <a href='https://img.ly/docs/pesdk/web/guides/react-js/#installing-photoeditor-sdk' target='__blank'>
                    Documantation
                </a>
            </div>

            <div
                id='editor'
                style={{ width: "50vw", height: "50vh" }}
            />
        </>
    )
}